<template>
  <div class="about-lime">
    <v-card color="white" width="100%" flat tile>
      <v-container class="py-10">
        <v-row>
          <!-- Privacy Policy -->
          <v-col cols="12">
            <h3 class="heading-h3">Privacy Policy</h3>
          </v-col>

          <v-col cols="12" lg="12">
            <!-- 1.	INTRODUCTION -->
            <h4 class="heading-h4 mb-4">1. INTRODUCTION</h4>

            <!-- paragraphsForSection1 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection1"
              :key="'paragraphs-for-section-1-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- 2.	THE CUSTOMER’S CONSENT -->
            <h4 class="heading-h4 mb-4">2. THE CUSTOMER’S CONSENT</h4>

            <!-- paragraphsForSection2 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection2"
              :key="'paragraphs-for-section-2-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- 3.	SECURITY AND CONFIDENTIALITY -->
            <h4 class="heading-h4 mb-4">3. SECURITY AND CONFIDENTIALITY</h4>

            <!-- paragraphsForSection3 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection3"
              :key="'paragraphs-for-section-3-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- 4.	PERSONAL INFORMATION -->
            <h4 class="heading-h4 mb-4">4. PERSONAL INFORMATION</h4>

            <!-- paragraphsForSection4 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection4"
              :key="'paragraphs-for-section-4-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- 5.	COLLECTING THE CUSTOMER’S DATA -->
            <h4 class="heading-h4 mb-4">5. COLLECTING THE CUSTOMER’S DATA</h4>

            <!-- paragraphsForSection5 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection5"
              :key="'paragraphs-for-section-5-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- 6.	COLLECTION AND USE OF PERSONAL INFORMATION -->
            <h4 class="heading-h4 mb-4">
              6. COLLECTION AND USE OF PERSONAL INFORMATION
            </h4>

            <!-- paragraphsForSection6 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection6"
              :key="'paragraphs-for-section-6-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- pulletsForSection6 -->
            <DefaultList
              :listItems="pulletsForSection6"
              class="mt-2 mb-n6 ml-4"
            />

            <!-- paragraphsForSection6Part2 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection6Part2"
              :key="'paragraphs-for-section-6-part-2-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- 7.	ADDITIONAL DATA COLLECTION -->
            <h4 class="heading-h4 mb-4">7. ADDITIONAL DATA COLLECTION</h4>

            <!-- paragraphsForSection7 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection7"
              :key="'paragraphs-for-section-7-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- 8.	PERSONAL IDENTIFICATION NUMBER -->
            <h4 class="heading-h4 mb-4">8. PERSONAL IDENTIFICATION NUMBER</h4>

            <!-- paragraphsForSection8 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection8"
              :key="'paragraphs-for-section-8-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- 9.	SHARING THE CUSTOMER’S DATA FOR SERVICE PROVISION -->
            <h4 class="heading-h4 mb-4">
              9. SHARING THE CUSTOMER’S DATA FOR SERVICE PROVISION
            </h4>

            <!-- paragraphsForSection9 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection9"
              :key="'paragraphs-for-section-9-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- pulletsForSection9 -->
            <DefaultList
              :listItems="pulletsForSection9"
              class="mt-2 mb-n6 ml-4"
            />

            <!-- 10. RIGHT TO REQUEST ACCESS TO THE CUSTOMER’S PERSONAL INFORMATION -->
            <h4 class="heading-h4 mb-4">
              10. RIGHT TO REQUEST ACCESS TO THE CUSTOMER’S PERSONAL INFORMATION
            </h4>

            <!-- paragraphsForSection10 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection10"
              :key="'paragraphs-for-section-10-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- 11. ELECTRONIC COMMUNICATIONS AND TRANSACTIONS ACT -->
            <h4 class="heading-h4 mb-4">
              11. ELECTRONIC COMMUNICATIONS AND TRANSACTIONS ACT
            </h4>

            <!-- paragraphsForSection11 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection11"
              :key="'paragraphs-for-section-11-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- pulletsForSection11 -->
            <DefaultList
              :listItems="pulletsForSection11"
              class="mt-2 mb-n6 ml-4"
            />

            <!-- paragraphsForSection11Part2 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection11Part2"
              :key="'paragraphs-for-section-11-part-2-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- pulletsForSection11Part2 -->
            <DefaultList
              :listItems="pulletsForSection11Part2"
              class="mt-2 mb-n6 ml-4"
            />

            <!-- 12. CONTACT FROM LIME LOANS AND THIRD PARTIES -->
            <h4 class="heading-h4 mb-4">
              12. CONTACT FROM LIME LOANS AND THIRD PARTIES
            </h4>

            <!-- paragraphsForSection12 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection12"
              :key="'paragraphs-for-section-12-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- 13. THIRD PARTY LINKS -->
            <h4 class="heading-h4 mb-4">13. THIRD PARTY LINKS</h4>

            <!-- paragraphsForSection13 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection13"
              :key="'paragraphs-for-section-13-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- 14. AGE -->
            <h4 class="heading-h4 mb-4">14. AGE</h4>

            <!-- paragraphsForSection14 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection14"
              :key="'paragraphs-for-section-14-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- 15. CHANGES TO THIS PRIVACY POLICY -->
            <h4 class="heading-h4 mb-4">15. CHANGES TO THIS PRIVACY POLICY</h4>

            <!-- paragraphsForSection15 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection15"
              :key="'paragraphs-for-section-15-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>

            <!-- 16. APPLICABLE LAWS -->
            <h4 class="heading-h4 mb-4">16. APPLICABLE LAWS</h4>

            <!-- paragraphsForSection16 -->
            <p
              v-for="(paragraph, index) in paragraphsForSection16"
              :key="'paragraphs-for-section-16-' + index"
              v-html="paragraph.text"
              class="b-body-1"
            ></p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import DefaultList from "@/components/shared/lists/DefaultList";

export default {
  name: "LimeLoansPrivacyPolicy",

  components: { DefaultList },

  data() {
    return {
      // paragraphsForSection1
      paragraphsForSection1: [
        {
          text: `The Privacy Policy applies to all current and former customers as 
                well as to Users, to the extent applicable, (collectively referred 
                to as the “Customers”) and any of the provisions and/or conditions 
                of the Privacy Policy may at any time be modified, amended, replaced, 
                or substituted. Kindly also refer to the terms and conditions of 
                the Loan Agreement (sent via email during the application), which 
                provide further details of how Lime Loans collects, processes, and 
                uses the Customer’s personal information and should be read together 
                with this Privacy Policy.`,
        },
      ],

      // paragraphsForSection2
      paragraphsForSection2: [
        {
          text: `By submitting details and/or using Lime Loans service the Customer 
                accepts the Privacy Policy and expressly provides voluntary, specific, 
                and informed consent to the use and disclosure of the Customer’s 
                personal information in the manner described below. The Customer has 
                the right to object to Lime Loans use of the Customer’s personal 
                information. Depending on the information the Customer objects to, 
                Lime Loans may not be able to proceed with the Customer’s loan 
                application/s as certain information is required for the conclusion 
                or performance of the credit application and/or loan agreement and 
                Lime Loans is obliged by law to obtain same.`,
        },
        {
          text: `The Customer agrees and warrants that the Customer has read, considered, 
                and understood the terms contained in this Privacy Policy and that this Privacy 
                Policy will constitute an on-going binding agreement between Lime Loans and 
                the Customer.`,
        },
      ],

      // paragraphsForSection3
      paragraphsForSection3: [
        {
          text: `Lime Loans understands the value of the Customer’s personal information 
                and therefore will take all reasonable steps to protect the Customer’s 
                personal information from loss, misuse, or unauthorised alteration. The 
                Customer’s personal information is stored in databases that have built-in 
                safeguards and firewalls to ensure the privacy and confidentiality of that 
                information.`,
        },
        {
          text: `Lime Loans recognises the need for appropriate protection and management 
                of the personal information the Customer shares with Lime Loans. Lime Loans 
                protects that information using secure socket layer (SSL) encryption 
                technology and by limiting employee access on a need-to-know basis. Lime Loans 
                stores data in encrypted form on computers and control access via secure web 
                pages. Lime Loans employs firewalls and other security technologies to protect 
                our servers from external attack.`,
        },
        {
          text: `Lime Loans security systems meet or exceed industry standards and Lime 
                Loans constantly monitors internet developments to ensure the systems 
                evolve as required. Lime Loans also test the systems regularly to make 
                sure the security mechanisms are up to date. Finally, Lime Loans are 
                subject to South African data protection laws, including the Constitution 
                of the Republic of South Africa of 1996, the Protection of Personal 
                Information Act No. 4 of 2013, the Electronic Communications and 
                Transactions Act, 2002 (“<strong>ECT Act</strong>”), the Regulation of 
                Interception of Communication Act No. 25 of 2002, the National Credit 
                Act No. 35 of 2005 read with the National Credit Amendment Act No. 19 
                of 2014 (“<strong>NCA</strong>”) and the Consumer Protection Act No. 
                68 of 2008 (“<strong>CPA</strong>”) which Lime Loans fully comply with.`,
        },
      ],

      // paragraphsForSection4
      paragraphsForSection4: [
        {
          text: `Personal information is information that identifies or relates specifically 
                to the Customer including the Customer’s financial information, for example, 
                name, age and identity number, the Customer’s assets and liabilities, 
                income, and payment records. In short, any information that Lime Loans knows 
                about the Customer will be regarded as the Customer’s personal information.`,
        },
      ],

      // paragraphsForSection5
      paragraphsForSection5: [
        {
          text: `Lime Loans collect information about the Customer in two ways - directly 
                from the Customer’s online application and from outside sources such as 
                credit bureaus and customer service providers. Lime Loans may use data 
                that identifies the Customer’s personal information for a number of reasons 
                including but not limited to statistical analysis, development and improvement 
                of Lime Loans products, assessing lending risks, updating the Customer’s 
                records, identifying which products or, our partners' products, might interest 
                the Customer, identifying and prevent fraud, money laundering and other 
                criminal activities, carrying out regulatory checks, keeping the Customer 
                informed about the Customer’s loan, doing market research, and products 
                and services Lime Loans offers on behalf of third parties.`,
        },
      ],

      // paragraphsForSection6
      paragraphsForSection6: [
        {
          text: `To make the Customer’s use of Lime Loans services as successful as possible, 
                it is necessary for Lime Loans to understand exactly what the Customer needs 
                and to ascertain whether in terms of the provisions of the NCA, the Customer 
                is eligible to qualify for a loan. The following are some of the reasons why 
                Lime Loans would collect the Customer’s personal information:`,
        },
      ],

      // pulletsForSection6
      pulletsForSection6: [
        {
          text: `To identify the Customer`,
        },
        {
          text: `To process the Customer’s instructions or requests`,
        },
        {
          text: `To ensure that the Customer meets the required lending criteria`,
        },
      ],

      // paragraphsForSection6Part2
      paragraphsForSection6Part2: [
        {
          text: `Lime Loans may collect and analyse the Customer’s personal information and 
                combine all the information that Lime Loans have about the Customer to 
                compile a credit profile of the Customer for Lime Loans to grant the Customer 
                a loan and to meet the Customer’s specific needs.`,
        },
        {
          text: `Once Lime Loans has collected and analysed the Customer’s personal 
                information, Lime Loans may send the Customer promotional material or 
                information which Lime Loans considers to be of interest to the Customer 
                but will only do so with the Customer’s explicit consent. If any of this 
                promotional information relates to products, promotions, news, or services 
                of a third party, and if the Customer indicates that the Customer would like 
                more details, Lime Loans may inform the third party to contact the Customer 
                directly. Lime Loans will however only inform a third party to contact the 
                Customer directly if the Customer has indicated that this is agreeable to 
                the Customer. In addition, Lime Loans utilises the Customer’s personal 
                information for other purposes as set out in this Privacy Policy.`,
        },
      ],

      // paragraphsForSection7
      paragraphsForSection7: [
        {
          text: `In addition to the personal information the Customer submits, Lime Loans may 
                collect information about the Customer’s computer including, where available, 
                the Customer’s IP address, operating system, and browser type, for purposes 
                of administration and to report, aggregated information to Lime Loans 
                advertising partners. This allows Lime Loans to have a better understanding 
                of the Customer’s requirements and/or preferences by using cookies to track 
                Customers as they travel from website to website recording what commercial 
                advertisements the Customer view’s and select, while browsing.`,
        },
        {
          text: `This is purely statistical data about browsing actions and patterns and does 
                not identify any individuals. Lime Loans may also obtain information about the 
                Customer’s general internet usage by using a cookie file which is stored on 
                the hard drive of the Customer’s computer, Cookies enables Lime Loans to 
                improve the services provided to the Customer, estimate Lime Loans audience 
                size and usage patterns, store information about the Customer’s preferences 
                and recognise the Customer when the Customer returns to the Website.`,
        },
        {
          text: `The Customer can set the Customer’s web browser to refuse cookies, however, 
                if the Customer does this, the Customer may not be able to enjoy full use of 
                the Lime Loans Website and the Customer may not be able to take advantage of 
                promotions Lime Loans may run from time to time. Please note that third parties 
                who advertise on Lime Loans Website may also use cookies, but Lime Loans do 
                not have access to, or have control over them, and therefore does not take 
                any responsibility in respect thereto whatsoever. All advertisers are bound 
                by a contract to maintain the confidentiality and security of the Customer’s 
                personal information and are restricted in their use thereof as per this 
                Privacy Policy.`,
        },
        {
          text: `Lime Loans wants to provide the Customer with the best possible service, 
                so calls will be recorded and/or monitored for quality and staff training 
                purposes. Recordings may also be used to help prevent and combat fraud.`,
        },
        {
          text: `To provide a customised and personalised service, we may use cookies to 
                store and help track information about the Customer. A cookie is a small 
                text file sent to your device that Lime Loans uses to store limited 
                information about the Customer’s use of the products on our website. 
                Lime Loans uses cookies to provide the Customer with certain functionality 
                (such as to enable access to secure log-in areas, and to save the Customer 
                having to re-enter information into product or website forms) and to 
                personalize Lime Loans products or Website content. Without cookies, this 
                functionality would be unavailable. By using Lime Loans products and 
                Websites the Customer agrees that Lime Loans can place these types of 
                cookies on the Customer’s device.`,
        },
      ],

      // paragraphsForSection8
      paragraphsForSection8: [
        {
          text: `When the Customer uses the Website and the services provided by Lime Loans, 
                the Customer may be given an access number, username, password and/or personal 
                identification number (“PIN”). The Customer is responsible for maintaining the 
                secrecy and confidentiality of the Customer’s username, password and/or PIN.`,
        },
      ],

      // paragraphsForSection9
      paragraphsForSection9: [
        {
          text: `Lime Loans shares the data the Customer submits with third parties 
                involved in the process of providing the services which the Customer 
                requests, such as credit providers, credit bureaus, customer service 
                providers, and collection agencies. Lime Loans has trust relationships 
                with these carefully selected third parties who perform services on 
                Lime Loans behalf. All service providers are bound by contract to maintain 
                the confidentiality and security of the Customers personal information 
                and are restricted in their use thereof, as per this Privacy Policy. The 
                service providers are required to notify Lime Loans immediately where there 
                are grounds to believe that the Customer’s personal information has been 
                accessed or acquired by any unauthorised person.`,
        },
        {
          text: `By clicking the 2 “<strong>Tick</strong>” boxes (Terms and Conditions, and Privacy Policy 
                respectively), the Customer agrees that Lime Loans may collect and disclose 
                the Customer’s personal information:`,
        },
      ],

      // pulletsForSection9
      pulletsForSection9: [
        {
          text: `If Lime Loans sells or buys any business or assets (in which case Lime Loans 
                may disclose the Customer’s personal information to the prospective seller or 
                buyer of such a business or assets)`,
        },
        {
          text: `If Lime Loans as a business, with part / all its assets are acquired by a 
                third party (in which case personal information held about Customers will be 
                one of the transferred assets)`,
        },
        {
          text: `If Lime Loans has a duty to disclose the Customer’s personal information to 
                comply with a legal request, or to enforce any of Lime Loans terms and 
                conditions.`,
        },
      ],

      // paragraphsForSection10
      paragraphsForSection10: [
        {
          text: `As aforementioned, Lime Loans is committed to the protection of the 
                Customer’s personal information and any information disclosed to third 
                parties is only done so with Lime Loans knowledge and express authorisation. 
                The Customer is entitled to request access to the Customer’s information at 
                a prescribed fee and to request that inaccurate, irrelevant, excessive, 
                out-dated, misleading, or unlawfully obtained information be corrected, 
                deleted or in the case of a dispute, indicate that a correction has been 
                requested, but not made.`,
        },
      ],

      // paragraphsForSection11
      paragraphsForSection11: [
        {
          text: `Lime Loans also voluntarily subscribes to the principles, outlined in Section 
                51 of the ECT Act, which govern the Customer’s right to have the Customer’s 
                personal information kept private. Lime Loans briefly outlines these principles 
                below:`,
        },
      ],

      // pulletsForSection11
      pulletsForSection11: [
        {
          text: `Lime Loans will only collect, collate, process and store (“<strong>Use</strong>”) 
                the Customer’s personal information with the Customer’s permission as set out in 
                this Privacy Policy, unless legally required to do so, and will only Use such 
                information for the lawful purpose for which it is required as set out in this 
                Privacy Policy.`,
        },
        {
          text: `Lime Loans hereby disclose in writing, the specific purpose for which Lime Loans 
                Use the Customer’s personal information. Lime Loans will also keep a record of that 
                personal information and the specific purpose for which Lime Loans have used it.`,
        },
        {
          text: `Lime Loans will not Use the Customer’s personal information for any purpose, 
                other than that which Lime Loans disclosed to the Customer herein, unless the 
                Customer gives Lime Loans express permission to do so, or unless Lime Loans is 
                permitted and/or required to do so by law.`,
        },
      ],

      // paragraphsForSection11Part2
      paragraphsForSection11Part2: [
        {
          text: `Further to the requirements of the ECT Act, Lime Loans will disclose the 
                Customer’s personal information without the Customer’s consent:`,
        },
      ],

      // pulletsForSection11Part2
      pulletsForSection11Part2: [
        {
          text: `If Lime Loans is required to do so by law or a court order`,
        },
        {
          text: `If disclosure is in the public’s interest`,
        },
        {
          text: `If disclosure is in Lime Loans interest with the Customer’s implied consent`,
        },
      ],

      // paragraphsForSection12
      paragraphsForSection12: [
        {
          text: `As part of the application process, Lime Loans asks the Customer whether Lime 
                Loans or any of Lime Loans partners may contact the Customer about Lime Loans 
                products and services by telephone, email, and SMS (or other reasonable 
                method). If the Customer selected “yes” but no longer wish to receive such 
                communication, kindly visit the “My Account” page, login and change the 
                Customer’s communication preference at any time or follow the instructions 
                in the message itself.`,
        },
        {
          text: `The Customer may also register on the National “Opt Out” register of the 
                Direct Marketing Association of South Africa by going to 
                <a href="https://dmasa.org/" 
                class="text-decoration-none font-weight-medium" target="_blank">
                  www.dmasa.org</a>.`,
        },
        {
          text: `The Customer hereby gives explicit consent to Lime Loans to transfer the 
                Customer’s personal information to selected third parties. If at any time, 
                the Customer wishes to stop receiving such contact from a third party, 
                the Customer will need to follow the instructions about unsubscribing 
                provided at that time, by the third party. Please note that Lime Loans 
                only partners with trusted companies who will respect the Customer’s 
                right to unsubscribe from any such marketing communications. Lime Loans 
                will not be responsible for managing that process or any disputes 
                originating from such communications.`,
        },
      ],

      // paragraphsForSection13
      paragraphsForSection13: [
        {
          text: `The Lime Loans Website may contain links to third party websites. If the 
                Customer follows a link to any of these websites, please note that these 
                websites have their own terms and conditions, and privacy policies. Lime Loans 
                does not accept any responsibility or liability for these Websites. By 
                registering at Lime Loans, the Customer may receive follow-up communication 
                and offers from third party companies., The Customer has agreed to do so, by 
                accepting this Privacy Policy and, while Lime Loans only works with selected 
                partners, Lime Loans is not responsible for the services or representations of 
                third parties.`,
        },
        {
          text: `Because Lime Loans is not responsible for any representations and/or 
                information and/or warranties and/or content on any website of any third party 
                (including websites linked to the Lime Loans Website, or websites facilitated 
                by Lime Loans), Lime Loans do not exercise control over third parties' privacy 
                policies and the Customer should refer to the privacy policy of any such third 
                party to see how such party protects the Customer’s privacy.`,
        },
      ],

      // paragraphsForSection14
      paragraphsForSection14: [
        {
          text: `To register with Lime Loans the Customer must be 18 years of age or older. 
                Minors are strictly forbidden from using the service and applying for credit 
                from Lime Loans.`,
        },
      ],

      // paragraphsForSection15
      paragraphsForSection15: [
        {
          text: `Lime Loans reserves the right, in its sole discretion, to update, modify or 
                amend (including without limitation, by the addition of new terms and 
                conditions) this Privacy Policy from time to time with or without notice. 
                The Customer therefore agrees to review the Privacy Policy whenever the 
                Customer visits the Website for any such change. Save as expressly provided 
                to the contrary in this Privacy Policy, the amended version of the Privacy 
                Policy shall supersede and replace all previous versions thereof.`,
        },
      ],

      // paragraphsForSection16
      paragraphsForSection16: [
        {
          text: `The Privacy Policy will be governed by and construed and interpreted in 
                accordance with the laws of South Africa to the extent that a court has 
                jurisdiction over any dispute which may arise out of or in connection with 
                this Privacy Policy. Both Lime Loans and the Customer submits to the 
                jurisdiction of the South African courts or dispute resolution and arbitration 
                through the South African Credit Ombud.`,
        },
      ],

      listItems1: [
        {
          text: `As one of the world’s leading digital lending internet platforms
                and financial services organisations, our objective is to help
                manage our customer’s financial affairs efficiently, smoothly and
                securely. We believe that maintaining a balanced financial
                position is vital and we see the value in being able to provide
                for our customer’s and their families.`,
        },
        {
          text: `We aim to help connect our customers to opportunities, reinvent
                themselves and help them realize their ambitions with our digital
                lending platform.`,
        },
        {
          text: `Lime is a financial technology company that provides digital
                solutions to change how people borrow money across South Africa.
                We are an entity of our larger holding company Lime Credit Group.
                Utilising advanced algorithms and machine learning capabilities,
                we are able to offer a platform for a customised approach towards
                lending to help unlock the purchasing power of millions, 24/7.`,
        },
        {
          text: `With our world-class team, and the combination of technology,
                analytics and customer service capabilities — we’ve built a
                company uniquely well-appointed to this task to offer a seamless
                process for loans, entirely online.`,
        },
      ],
      listItems2: [
        {
          text: `The Company Intellectual Property Commission – Registration
             Number 2015/239349/07`,
        },
        {
          text: `The National Credit Regulator – Registration Number NCRCP8077 `,
        },
      ],
      listItems3: [
        {
          text: `Address: C505, Bridgewater 3, Century City, Cape Town, 7441`,
        },
        {
          text: `Phone: <a href="tel:+27104426722"
                  class="text-decoration-none font-weight-medium" target="_blank">
                  010 442 6722</a>`,
        },
        {
          text: `Email: <a href="mailto:support@lime24.co.za"
                  class="text-decoration-none font-weight-medium" target="_blank"
                >support@lime24.co.za</a>`,
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
